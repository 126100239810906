import React, { useState, useEffect, useContext } from 'react';
import { marked } from 'marked';
import Helmet from 'react-helmet';

import ReactHTMLParser from 'react-html-parser';
import { Box } from '@latitude/box';
import HowToApply from '@latitude/how-to-apply';
import { ImportantInformation } from '@latitude/important-information';
import { ListNumbered } from '@latitude/list-numbered';
import { StickyNavigation } from '@latitude/sticky-navigation';
import {
  BUTTON_STYLE,
  COLOR,
  MARGIN,
  ALIGN
} from '@latitude/core/utils/constants';
import { FeaturesSlider } from '@latitude/features-slider';
import RatesAndFees from '@latitude/rates-and-fees';
import { Strong } from '@latitude/text';
import { StickyCta } from '@latitude/sticky-cta';
import { Link } from '@latitude/link';
import { List, ListItem } from '@latitude/list';
import { Heading4 } from '@latitude/heading';
import { AccordionSidebar } from '@latitude/accordion';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';

import DigitalWallets from '@/components/DigitalWallets/DigitalWallets';
import Button from '@/components/Button/Button';
import CalloutBox from '@/components/CalloutBox/CalloutBox';
import Layout from '@/components/layout';
import Image from '@/components/Image/Image';

import PageData from '../../../data/pages/gem-visa-card.json';
import withStickyState from '../../../hoc/withStickyState';
import '../gem-visa-card.scss';
import { featuresSliderIE11Fix } from '../../../utils/stylesUtils';
import PaymentOptions from '../_payment-options';
import { getUrlFromEnv } from '../../../utils/getUrlFromEnvUtil';
import StyledRatesBox from '../_styled-rates-box';
import CalloutHelp from '../_callout-help';
import faqsCredit from '../_faqs';
import CarouselHeader from '@/components/Carousel/carosuelHeader';
import heroImage from '../../../images/gemfinance-banner.webp';
import bookingImage from '../../../images/booking-banner-nz.webp';
import { PageContext } from '../../../context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { BREAKPOINT, CONTENTFUL_COMPONENTS } from '../../../utils/constants';
import { useSetState } from '@/utils/hooks';

const GemVisaPage = props => {
  const applyUrl = getUrlFromEnv('funnel-eapps-start-application');

  const HEADER_ITEMS = [
    {
      title: 'Shop today with more time to pay',
      text:
        'Enjoy 6 months Interest Free on everyday purchases of $250 or more with your Gem Visa credit card.',
      termsconds:
        '<sup>*</sup>T&Cs & fees apply incl. a $55 estb. fee & $65 annual fee (charged $32.50 half yearly). Interest rate currently 29.49% p.a. applies after interest free term ends. For cash advances, an interest rate of 29.95% p.a. and a $2 fee applies. Further information on rates and fees can be found at gemfinance.co.nz.  Credit provided by Latitude Financial Services Limited.',
      headerImage: heroImage,
      urlFindOutMore: '',
      urlApplyNow: applyUrl
    },
    {
      title:
        'Get an instant 6% off selected accommodation at Booking.com<sup>1</sup>',
      text:
        'Available all day, every day, year-round with your Gem Visa credit card via the Latitude App.',
      termsconds: '',
      headerImage: bookingImage,
      urlFindOutMore: '',
      urlApplyNow: 'https://cards.gemvisa.co.nz/'
    }
  ];

  const featureSliderItems = [
    {
      icon: 'icon-lrpl',
      title: (
        <>
          6 months interest free
          <sup>*</sup>
        </>
      ),
      text:
        'Spend $250 or more,<sup>*</sup> anywhere Visa is accepted. Use it instore, online and worldwide.'
    },
    {
      icon: 'icon-cardless-branded',
      title: 'Get cash back credit',
      text:
        'Swipe some awesome deals with your Gem Visa and get cash back credit. Find the deals <a class="text-underline" href="https://www.offers-exchange.com/gemvisa/">here</a>.'
    },
    {
      icon: 'icon-easy',
      title: 'Long term interest free deals',
      text:
        'We\'ve joined with some of New Zealand\'s leading brands so you can enjoy long term interest free deals.<sup>*</sup> Find out more <a class="text-underline" href="/credit-cards/interest-free-offers">here</a>.'
    },
    {
      icon: 'icon-iphone',
      title: 'Digital self-servicing',
      text:
        'Download the <a class="text-underline" href="https://www.gemfinance.co.nz/mobile-app/">Latitude App</a> to stay up to date on your balance, transactions and more;'
    },
    {
      icon: 'icon-pl-2',
      title: 'Booking.com partnership',
      jsx: (
        <p
          css={`
            font-family: Roboto, Open Sans, sans-serif;
            font-size: 16px;
            font-weight: 300;
            line-height: 26px;
            text-align: center;
            color: #42484d;
          `}
        >
          Get 6% off your stay instantly with Booking.com every day,
          all year round via the{' '}
          <a href="/mobile-app">Latitude App</a>
          <sup>1</sup>.
        </p>
      )
    }
  ]

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);

  const [state, setState] = useSetState({
    heroBannerData: [],
    featureSliderData: [
      {
        heading: 'What makes Gem Visa different?',
        featureCards: featureSliderItems
      }
    ]
  });

  useEffect(() => {
    let [featureSliders, heroBanners] = [[], []];
    contentfulPageData?.body?.map(item => {
      switch (item.contentType.sys.id) {
        case CONTENTFUL_COMPONENTS.FEATURE_SLIDER:
          const updatedItem = {
            heading: marked(item.heading || ""),
            description: ReactHTMLParser(marked(item.description || "")),
            featureCards: item.featureCards?.map(card => ({
              ...card,
              text: marked(card.text || ""),
            }))
          };
          featureSliders = [...featureSliders, updatedItem];
          break;
        case CONTENTFUL_COMPONENTS.HERO_BANNER_CAROUSEL:
          heroBanners = [...heroBanners, item];
          break;
        default:
          break;
      }
    });

    setState({
      heroBannerData: heroBanners.length > 0 ? heroBanners : state.heroBannerData,
      featureSliderData: featureSliders.length > 0 ? featureSliders : state.featureSliderData,
    });
  }, [contentfulPageData]);
  /** - END - */

  return (
    <Layout hasStickyNav>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer navigation-spacer--hasStickyNav gem-visa-page">
        <Box backgroundColor={COLOR.GREY6}>
          <Helmet>
            <link
              rel="canonical"
              href="https://www.gemfinance.co.nz/credit-cards/gem-visa-card/"
            />
            <title>
              Gem Visa Credit Card | Interest Free Credit Cards NZ | Gem by
              Latitude
            </title>
            <meta
              name="description"
              content="Enjoy 6 months int. free on all Gem Visa purchases of $250 and over. T&amp;Cs and $65 annual (charged $32.50 half-yearly) fees apply. Int. rate 29.99% p.a. applies after int. free term ends."
            />
          </Helmet>
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <CarouselHeader HEADER_ITEMS={HEADER_ITEMS} />
          )}
          <div className="d-lg-none d-xl-none">
            <StickyCta href={applyUrl} trackId="sticky-cta--apply-now" />
          </div>
          <div className="d-none d-lg-block">
            <StickyNavigation
              items={PageData.nav}
              phoneNumber={PageData.content.phoneNumber}
              isSticky={props.isNavSticky}
              ctaHref={applyUrl}
              ctaTarget="_self"
              ctaText="Apply now"
              onStickyNavStateChange={props.handleStickyNavStateChange}
              offsetElem="[data-sticky-navigation-offset]"
              trackId="sticky-nav-apply-now"
              trackEventData={{
                category: 'cta',
                action: 'application-link',
                label: 'Apply now',
                location: 'Sticky Navigation'
              }}
              trackProductId={['CCNZGEM']}
            />
          </div>
          {state?.featureSliderData?.[0] && (
            <FeaturesSlider
              id="why-us"
              data={state.featureSliderData[0].featureCards}
              className="pb-0"
              css={
                featuresSliderIE11Fix +
                `
            h5 {
              @media (max-width: ${BREAKPOINT.XS}) {
                height: 48px
              }
              @media (min-width: ${BREAKPOINT.SM}) {
                height: 64px
              }
            }`
              }
              heading={state.featureSliderData[0].heading}
              subheading={state.featureSliderData[0].description}
              responsiveSettings={
                PageData.whyChooseLatitudeResponsoveSettings
              }
            />
          )}
          <CalloutBox
            id="balance-transfer"
            title="Balance Transfer"
            desc={
              <>
                If you have money owing on a non-Latitude credit card, you can
                now move it over to your Gem Visa credit card.
              </>
            }
            cta={
              <div className="row">
                <div className="col-12 col-lg-6">
                  <Button
                    secondary
                    href="/credit-cards/gem-visa-card/balance-transfer/"
                    trackId="gem-visa-balance-transfer"
                    trackEventData={{
                      category: 'button-link',
                      action: 'internal-link',
                      label: 'Find Out More',
                      location: 'Balance Transfer'
                    }}
                    trackProductId={['CCNZGEM']}
                  >
                    Find Out More
                  </Button>
                </div>
              </div>
            }
          />

          <RatesAndFees
            id="rates"
            title="Rates, Fees &amp; Conditions Of Use"
            className="rates-list-section"
            ratesListProps={{ data: PageData.ratesList }}
            accordionProps={{
              items: [
                {
                  id: 'account-fees',
                  title: 'Other rates and account fees',
                  content: (
                    <div className="accordion-custom">
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Interest rate on balance transfers</strong>
                        </p>
                        <p className="w-100">
                          Please refer to the Balance Transfer interest rate and
                          term set out in any offer presented to you.
                        </p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Annual Account Fee</strong>
                        </p>
                        <p className="w-100">
                          $65 (charged $32.50 half-yearly)
                        </p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Establishment Fee (One Off)</strong>
                        </p>
                        <p className="w-100">$55</p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Paper Statement Fee</strong>
                        </p>
                        <p className="w-100">
                          $1 per statement (avoid this by opting out of paper
                          statements)
                        </p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Replacement Card Fee</strong>
                        </p>
                        <p className="w-100">$5</p>
                      </div>
                    </div>
                  )
                },
                {
                  id: 'transaction-fees',
                  title: 'Transaction fees',
                  content: (
                    <StyledRatesBox className="accordion-custom">
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Cash advance fee</strong>
                        </p>
                        <p className="w-100">
                          <ul>
                            <li>$2 - over the counter cash advance</li>
                            <li>$1 - NZ ATM withdrawal</li>
                            <li>$1.50 - International ATM Withdrawal</li>
                            <li>Other ATM fees may apply</li>
                          </ul>
                        </p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>International Transaction Fee</strong>
                        </p>
                        <p className="w-100">
                          2% of the purchase or cash advance
                        </p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Late fee</strong>
                        </p>
                        <p className="w-100">$15</p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Payment handling fee</strong>
                        </p>
                        <p className="w-100">
                          <ul>
                            <li>
                              $0 when you pay via internet banking, direct debit
                              or automatic payment.
                            </li>
                            <li>
                              $2.70 payment handling fee for over the counter
                              payments at NZ Post Shop branches.
                            </li>
                          </ul>
                        </p>
                      </div>

                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Other fees</strong>
                        </p>
                        <p className="w-100">
                          $5 for statement reprints or replacement cards
                        </p>
                      </div>
                    </StyledRatesBox>
                  )
                },
                {
                  id: 'interchange-fees',
                  title: 'Interchange fees',
                  content: (
                    <div className="accordion-custom">
                      <div css="padding: 20px 30px 0 30px">
                        Interchange fees are paid by a business that processes
                        card transactions on behalf of a merchant (acquirer) to
                        the card issuer (Latitude Financial Services Limited in
                        the case of Gem Visa). The interchange fees payable for
                        all Gem Visa domestic Credit Card transactions are set
                        out below.
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Industry program: Charities</strong>
                        </p>
                        <p className="w-100">0.39%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants Card Present (CP) and Card Not
                            Present (CNP): Rate 1
                          </strong>
                        </p>
                        <p className="w-100">0.80%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants (CP and CNP): Rate 2
                          </strong>
                        </p>
                        <p className="w-100">0.65%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants (CP and CNP): Rate 3
                          </strong>
                        </p>
                        <p className="w-100">0.60%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants (CP and CNP): Rate 4
                          </strong>
                        </p>
                        <p className="w-100">0.50%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Strategic Merchants (CP and CNP): Rate 5
                          </strong>
                        </p>
                        <p className="w-100">0.45%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Tokenized Online – Classic, Gold</strong>
                        </p>
                        <p className="w-100">0.55%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Industry Program: Insurance</strong>
                        </p>
                        <p className="w-100">$1</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Industry Program: Recurring Payment</strong>
                        </p>
                        <p className="w-100">0.70%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>
                            Industry Program: Government, Utilities and Telecom
                          </strong>
                        </p>
                        <p className="w-100">$0.70</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Contactless</strong>
                        </p>
                        <p className="w-100">0.65%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Electronic</strong>
                        </p>
                        <p className="w-100">0.80%</p>
                      </div>
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          <strong>Standard</strong>
                        </p>
                        <p className="w-100">0.80%</p>
                      </div>
                    </div>
                  )
                },
                {
                  id: 'conditions',
                  title: 'Gem Visa Conditions of Use',
                  content: (
                    <div className="accordion-custom">
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          These are the Conditions of Use, <br />
                          which govern your Gem Visa credit card
                        </p>
                        <p className="w-100">
                          <a href="https://assets.latitudefinancial.com/legals/conditions-of-use/gemvisa-nz/cou.pdf">
                            <img
                              width="100px"
                              src="/media/gemvisa-cou.png"
                              alt="gemvisa-cou"
                            />
                          </a>
                        </p>
                      </div>
                    </div>
                  )
                },
                {
                  id: 'disclosure-statement',
                  title: 'Initial Disclosure Statement',
                  content: (
                    <div className="accordion-custom">
                      <div className="d-lg-flex accordion__row">
                        <p className="w-100">
                          This is a generic version of the{' '}
                          <a
                            href="https://assets.latitudefinancial.com/gem-essentials/nz-gem-visa-ids.pdf"
                            target="_blank"
                          >
                            Gem Visa Initial Disclosure Statement
                          </a>
                          . A personalized version is provided during the
                          application process.
                        </p>
                      </div>
                    </div>
                  )
                }
              ]
            }}
          />
          <PaymentOptions />
          <DigitalWallets />
          <HowToApply
            id="how-to-apply"
            box1={
              <>
                <Heading4
                  color={COLOR.BLACK}
                  marginBottom={MARGIN.M16}
                  isResponsive={false}
                >
                  How to apply
                </Heading4>
                <ListNumbered
                  data={PageData.content.howToApply}
                  viewStyle="compact"
                  separator="none"
                  counterBgColor={COLOR.BLUE_DEEP}
                />
              </>
            }
            box2={
              <>
                <Heading4
                  color={COLOR.BLACK}
                  marginBottom={MARGIN.M16}
                  isResponsive={false}
                >
                  Eligibility
                </Heading4>
                <Strong
                  css={`
                    display: block;
                    margin-bottom: ${MARGIN.M8};
                  `}
                >
                  You must:
                </Strong>
                <List>
                  {PageData.content.eligibilityCriteria[0].items.map(
                    (item, i) => (
                      <ListItem key={i}>{item}</ListItem>
                    )
                  )}
                </List>
                <Strong
                  css={`
                    display: block;
                    margin-top: ${MARGIN.M16};
                    margin-bottom: ${MARGIN.M8};
                  `}
                >
                  Able to supply:
                </Strong>
                <List>
                  {PageData.content.eligibilityCriteria[1].items.map(
                    (item, i) => (
                      <ListItem key={i}>{item}</ListItem>
                    )
                  )}
                </List>
              </>
            }
          />

          <Box id="faq">
            <Box.Section>
              <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
                Frequently asked questions
              </Heading4>
              <AccordionSidebar data={faqsCredit.gemVisaCard} />
            </Box.Section>
          </Box>

          <CalloutBox
            id="creditline"
            title="Are you an existing Gem CreditLine customer?"
            image={
              <Image
                src={require('../../../images/credit-cards/creditline.png')}
                title="Gem CreditLine"
                alt="Gem CreditLine"
              />
            }
            desc={
              <>
                If you have a Gem CreditLine card, and don't want to switch to a
                Gem Visa, don't worry &mdash; your card will still work at over
                2,000 retailers around New Zealand to access long term interest
                free deals.<sup>*</sup>
              </>
            }
            cta={
              <div className="row">
                <div className="col-12 col-lg-6">
                  <Button
                    secondary
                    href="/credit-cards/gem-creditline-card/"
                    trackId="creditline-find-out-more"
                    trackEventData={{
                      category: 'button-link',
                      action: 'internal-link',
                      label: 'Find Out More',
                      location: 'Are you an existing Gem CreditLine customer?'
                    }}
                    trackProductId={['CCNZGEM']}
                  >
                    Find Out More
                  </Button>
                </div>
              </div>
            }
          />

          <CalloutHelp
            cta={
              <Box width="210px">
                <Link
                  button={BUTTON_STYLE.PRIMARY}
                  href={applyUrl}
                  trackId="help-banner-apply"
                  trackEventData={{
                    category: 'cta',
                    action: 'application-link',
                    location: "We're here to help"
                  }}
                  trackProductId={['CCNZGEM']}
                  style={{ marginTop: 24 }}
                >
                  Apply now
                </Link>
              </Box>
            }
          />
          <ImportantInformation
            id="important-information"
            data={require('../../../data/json/disclaimer/gem-visa.json')}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          />
        </Box>
      </main>
    </Layout>
  );
};

const GemVisaPageWithSticky = withStickyState(GemVisaPage);

export default GemVisaPageWithSticky;
